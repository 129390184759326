import React, { useCallback, useEffect } from "react";
import { Alert, Textarea } from "@material-tailwind/react";
import SelectPatient, { FileDropZone } from "../SelectPatient";

import Order from "../../services/API/clients/OrderAPI";
import { toast } from "react-toastify";
import moment from "moment";
import JawTrackerAPI from "../../services/API/clients/JawTrackerAPI";
import PictureDropDown from "../PictureDropDown";
import JawTrackerDropDown from "../JawTrackerDropDown";

export default function PatientModal({ setOpen, data }: Readonly<{ setOpen: (open: string, arg0?: boolean) => void; data: any }>) {
    const [order, setOrder] = React.useState<any>({});
    const [files, setFiles] = React.useState<any>({});
    const [loadingTracker, setLoadingTracker] = React.useState<boolean>(false);
    const [loadingClient, setLoadingClient] = React.useState<boolean>(false);

    const fetchFiles = useCallback(async () => {
        const response = await Order.getFiles(data.id);
        if (response.status === 200) setFiles(response.body);
    }, [data]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    useEffect(() => {
        setOrder(data);
    }, [data]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const data = {
            id: order.id,
            patient: {
                firstName: order.patientFirstName,
                lastName: order.patientLastName,
            },
            comment: order.comment,
        };

        const response = await Order.updateOrder(data);
        if (response.status === 201) {
            toast.success("La commande a bien été modifiée");
            setOpen("invalid", true);
        } else {
            toast.error("Une erreur est survenue lors de la modification de la commande");
        }
    };

    const handleInputChange = (event: any) => {
        setOrder({ ...order, comment: event.target.value });
    };

    const cancelOrder = async () => {
        const data = {
            targetId: order.id,
            comment: "Annulation de la commande",
        };

        const response = await Order.cancel(data);
        if (response.status === 201 || response.status === 200) {
            toast.success("La commande a bien été annulée");
            setOpen("invalid", true);
        } else {
            toast.error("Une erreur est survenue lors de l'annulation de la commande");
        }
    };

    const getMyFile = async (id: string, name: string) => {
        await Order.downloadFile(id, name);
    };

    const handleFilesChange = async (event: any) => {
        if (event.target.files.length === 0) return;
        if (loadingClient) return;
        setLoadingClient(true);
        const newFiles = Array.from(event.target.files);
        if (newFiles.length === 0) return;
        const datas = new FormData();
        newFiles.forEach((file: any) => {
            datas.append("files", file);
        });
        datas.append("orderId", data.id);

        const response = await Order.uploadFiles(datas);
        if (response.status === 201) {
            toast.success("Les fichiers ont bien été uploadés");
            fetchFiles();
            setLoadingClient(false);
        } else if (response.status === 400) {
            toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
            setLoadingClient(false);
        } else if (response.status === 409) {
            toast.error("Ces fichiers existent déjà");
            setLoadingClient(false);
        } else if (response.status === 413) {
            toast.error("Le fichier est trop volumineux");
            setLoadingClient(false);
        } else {
            toast.error("Une erreur est survenue lors de l'upload des fichiers");
            setLoadingClient(false);
        }
    };

    const handleNewJawTracker = async (event: any) => {
        if (event.target.files.length === 0) return;
        if (loadingTracker) return;
        setLoadingTracker(true);
        try {
            const newFiles = Array.from(event.target.files);
            await newFiles.forEach(async (file: any) => {
                const datas = new FormData();
                datas.append("file", file);
                console.log(file)
                console.log(JSON.stringify(datas.getAll("files")))
                const resp = await JawTrackerAPI.uploadFiles(datas, data.patientId, data.id);
                if (resp.status !== 200 && resp.status !== 201) {
                    toast.error("Une erreur est survenue lors de la mise en ligne des fichiers LIO MOVE")
                } else {
                    toast.success("Les fichiers ont bien été mis en ligne");
                    setLoadingTracker(false);
                }            
            })
        } catch (error) {
            console.error("Une erreur est survenue lors de l'upload des fichiers : ", error);
            toast.error("Une erreur est survenue lors de la mise en ligne des fichiers LIO MOVE");
        } finally {
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 overflow-y-hidden">
                {order.statuses !== null &&
                    order.statuses !== undefined &&
                    order.statuses.filter((item: { state: string }) => item.state === "INVALID").pop() && (
                        <div className="flex flex-col items-start gap-4 w-full rounded-lg bg-gray-50 p-4 mb-2">
                            <b className="text-capucine-700">Motif de refus : </b>
                            {order.statuses !== null &&
                                order.statuses !== undefined &&
                                order.statuses.filter((item: { state: string }) => item.state === "INVALID").pop()?.comment}
                        </div>
                    )}
                <h4 className="text-capucine-700 font-bold font-sans">Informations de base</h4>
                <Alert className="my-2" color="deep-purple">
                    <b>Echéance : </b> {moment(order.deliveryTarget).format("DD.MM.YYYY")}
                </Alert>
                <hr className="mt-2 border-royal-900" />
                <div className="flex flex-col md:flex-row gap-4 mt-4">
                    <SelectPatient order={order} setOrder={setOrder} />
                    <div className="w-full md:w-1/2 relative">
                        <Textarea
                            label="Commentaire"
                            color="deep-purple"
                            value={order.comment}
                            onChange={(event) => handleInputChange(event)}
                            className="block"
                            containerProps={{
                                className: "h-full",
                            }}
                        />
                    </div>
                </div>
                <div className="flex gap-6 mt-4">
                    <PictureDropDown
                        fetchFiles={fetchFiles}
                        id="files-client"
                        orderId={order.id}
                    />
                    <JawTrackerDropDown
                        data={order}
                        fetchJawTracker={fetchFiles}
                        id="jawTracker"
                    />
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                >
                    Modifier la commande
                </button>
                <button
                    type="button"
                    onClick={() => cancelOrder()}
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                    Annuler la commande
                </button>
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen("editpatient")}
                >
                    Fermer
                </button>
            </div>
        </form>
    );
}
